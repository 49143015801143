import { createSlice } from '@reduxjs/toolkit';

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        stores: [],
        selectedStoreID: null,
        selectedCategory: null,
        searchKeyword: '',
        selectedGrades: [],
        startDate: null,
        endDate: null,
    },
    reducers: {
        setStores: (state, action) => {
            state.stores = action.payload;
        },
        setSelectedStoreID: (state, action) => {
            state.selectedStoreID = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSearchKeyword: (state, action) => {
            state.searchKeyword = action.payload;
        },
        addSelectedGrade: (state, action) => {
            state.selectedGrades.push(action.payload);
            state.selectedGrades.sort();
        },
        removeSelectedGrade: (state, action) => {
            const index = state.selectedGrades.indexOf(action.payload);
            if (index > -1) {
                state.selectedGrades.splice(index, 1);
                state.selectedGrades.sort();
            }
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        }
    },
})

export const {
    setStores,
    setSelectedStoreID,
    setSelectedCategory,
    setSearchKeyword,
    addSelectedGrade,
    removeSelectedGrade,
    setStartDate,
    setEndDate,
} = filtersSlice.actions;

export default filtersSlice.reducer;