import { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import './styles.scss';
import { DateRangePicker } from 'react-date-range';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';
import moment from 'moment';
import SalesChart from './sales-chart';
import { getDataFromManagerAPI, getDataFromStudioAPI } from '../../../utils/query';
const COLOR_PALETTE = ['#ff5721', '#2096f3', '#3e4fb5', '#009688', '#9c27b0', '#ffc007', '#607d8a', '#00bcd4', '#8bc24a', '#795548', '#0d6efd', '#6c757d', '#198754', '#dc3545'];

const RevenuePage = () => {
    const [report, setReport] = useState([]);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [selectedDataType, setSelectedDataType] = useState('earnings');
    const [selectedXAxisType, setSelectedXAxisType] = useState('day');
    const [dateRange, setDateRange] = useState([
        {
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate(),
            key: 'selection'
        }
    ]);
    const [summary, setSummary] = useState();
    const [storeSummary, setStoreSummary] = useState({});
    const [loading, setLoading] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
        if (stores.length === 0) {
            fetchStores();
        } else {
            fetchSales();
        }
    }, [stores]);

    useEffect(() => {
        fetchSales();
    }, [selectedStore, selectedDataType, selectedXAxisType]);

    const fetchStores = async () => {
        const res = await getDataFromStudioAPI('stores/?active=True');
        setStores(res.data.map((store, index) => {
            return { ...store, color: COLOR_PALETTE[index] };
        }));
    }

    const fetchSales = async () => {
        setLoading(true);
        const startDate = moment(dateRange[0].startDate);
        const endDate = moment(dateRange[0].endDate);
        // endDate.set({hour: 23, minute: 59, second: 59});
        const res = await getDataFromManagerAPI('sales/get-revenue-report/?startDate=' + startDate.format('YYYY-MM-DD') + '&endDate=' + endDate.format('YYYY-MM-DD'));
        const report = res.data;
        // const formattedSales = [];
        const storeSummary = {};
        stores.forEach(store => {
            storeSummary[store.id] = 0;
        });

        let summary = 0;
        report.forEach(dayData => {
            stores.forEach(store => {
                storeSummary[store.id] += dayData[store.id + '_revenue'];
                if (selectedDataType === 'earnings') {
                    if (dayData[store.id + '_revenue'] !== undefined) {
                        summary += dayData[store.id + '_revenue'];
                    }
                } else {
                    if (dayData[store.id + '_units'] !== undefined) {
                        summary += dayData[store.id + '_units'];
                    }
                }
            });
        })

        // if (selectedXAxisType === 'day') {
        //     for (let d = startDate; d.isBefore(endDate); d.add(1, 'days')) {
        //         const startTimeThisDay = d.clone().add(11, 'hours');
        //         const endTimeThisDay = d.clone().add(35, 'hours');
        //         const salesThisDay = sales.filter(s => {
        //             return moment(s.createdAt).isAfter(startTimeThisDay) && moment(s.createdAt).isBefore(endTimeThisDay);
        //         });
        //         const formattedSale = {
        //             day: d.format('DD/MM'),
        //             fullDate: d.format('YYYY-MM-DD'),
        //             totalRevenue: 0,
        //             totalUnitSolds: 0,
        //         }
        //         for (let j = 0; j < stores.length; j++) {
        //             const storeSalesThisDay = salesThisDay.filter(s => s.store === stores[j].id);
        //             const revenue = storeSalesThisDay.reduce((revenue, item) => revenue + item.revenue, 0);
        //             const unitsSold = storeSalesThisDay.length;
        //             if (selectedDataType === 'earnings') {
        //                 formattedSale[stores[j].name] = parseFloat(revenue.toFixed(1));
        //                 formattedSale['totalRevenue'] += parseFloat(revenue.toFixed(1));
        //                 summary += revenue;
        //                 storeSummary[stores[j].id] += revenue;
        //             } else {
        //                 formattedSale[stores[j].name] = unitsSold;
        //                 formattedSale['totalUnitSolds'] += unitsSold;
        //                 summary += unitsSold;
        //                 storeSummary[stores[j].id] += unitsSold;
        //             }
        //         }
        //         formattedSales.push(formattedSale);
        //     }
        // } else {
        //     for (let d = startDate; d.isBefore(endDate); d.add(1, 'months')) {
        //         const salesThisMonth = sales.filter(s => s.createdAt.substr(0, 7) === d.format('YYYY-MM')) ;
        //         const formattedSale = {
        //             month: d.format('MM/YYYY'),
        //         }
        //         for (let j = 0; j < stores.length; j++) {
        //             const storeSalesThisMonth = salesThisMonth.filter(s => s.store === stores[j].id);
        //             const revenue = storeSalesThisMonth.reduce((revenue, item) => revenue + item.revenue, 0);
        //             const unitsSold = storeSalesThisMonth.length;
        //             if (selectedDataType === 'earnings') {
        //                 formattedSale[stores[j].name] = parseFloat(revenue.toFixed(1));
        //                 summary += revenue;
        //                 storeSummary[stores[j].id] += revenue;
        //             } else {
        //                 formattedSale[stores[j].name] = unitsSold;
        //                 summary += unitsSold;
        //                 storeSummary[stores[j].id] += unitsSold;
        //             }
        //         }
        //         formattedSales.push(formattedSale);
        //     }
        // }
        // setSales(formattedSales);
        if (selectedDataType === 'earnings') {
            setSummary('$' + parseInt(summary));
        } else {
            setSummary(summary + ' units');
        }
        setReport(report);
        setStoreSummary(storeSummary);
        setLoading(false);
    }

    return (
        <Container className='revenue-stat-container' ref={ref}>
            <div className='filter-summary'>
                <div className='filter'>
                    <select className="form-select select-data-type" onChange={e => setSelectedDataType(e.target.value)}>
                        <option selected value='earnings'>Earnings</option>
                        <option value='units-sold'>Units sold</option>
                    </select>
                    <OverlayTrigger trigger="click" placement="bottom" container={ref}
                        overlay={
                            <Popover>
                                <Popover.Body>
                                    <DateRangePicker
                                        ranges={dateRange}
                                        onChange={item => setDateRange([item.selection])}
                                    />
                                    <Button variant='success' onClick={fetchSales}>Apply</Button>
                                </Popover.Body>
                            </Popover>
                        }>
                        <div className='date-range'>
                            <Moment format="DD/MM/YYYY">
                                {dateRange[0].startDate}
                            </Moment>
                            -
                            <Moment format="DD/MM/YYYY">
                                {dateRange[0].endDate}
                            </Moment>
                        </div>
                    </OverlayTrigger>
                    <select className="form-select select-store"
                        onChange={e => {
                            setSelectedStore(e.target.value === 'all-stores' ? null : e.target.value);
                        }}>
                        <option selected value='all-stores'>All Stores</option>
                        {stores.map(store => (
                            <option value={store.id} key={store.id}>
                                {storeSummary[store.id] ? (store.name + ' - $' + storeSummary[store.id]) : store.name}
                            </option>
                        ))}
                    </select>
                    <select className="form-select select-x-axis-type"
                        onChange={e => {
                            setSelectedXAxisType(e.target.value);
                        }}>
                        <option selected value="day">By day</option>
                        <option value="month">By month</option>
                    </select>
                </div>
                {loading && (
                    <div>Loading...</div>
                )}
                {summary && !loading && (
                    <div className='summary'>
                        {summary}
                    </div>
                )}
            </div>

            {report.length > 0 && (
                <SalesChart report={report} selectedXAxisType={selectedXAxisType} stores={stores}
                    selectedDataType={selectedDataType} dateRange={dateRange[0]} />
            )}
        </Container>
    );
}

export default RevenuePage;