import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import Constant from '../../../Constant';
import './styles.scss';
import Spinner from 'react-bootstrap/Spinner';
import { getDataFromManagerAPI, updateDataManagerAPI } from '../../../utils/query';

const EditProductModal = ({ product, closeModal }) => {
    const [customID, setCustomID] = useState(product.customID);
    const [rateStatus, setRateStatus] = useState(product.rateStatus);
    const [saleStatus, setSaleStatus] = useState(product.saleStatus);
    const [ratePrior, setRatePrior] = useState(product.ratePrior);
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [idea, setIdea] = useState(null);
    const [minRates, setMinRates] = useState();

    useEffect(() => {
        setUserRole(localStorage.getItem(Constant.LOCAL_STORAGE_ROLE));
        fetchIdea();
    }, []);

    const fetchIdea = async () => {
        const res = await getDataFromManagerAPI('resources/get-product-idea-by-customID?customID=' + product.customID);
        setIdea(res.data);
        setMinRates(res.data['minRates']);
    }

    const update = async () => {
        setLoading(true);
        const resProduct = await updateDataManagerAPI('PATCH', 'resources/products/' + product.id + '/', { customID, rateStatus, saleStatus });
        let resIdea = await getDataFromManagerAPI('resources/get-product-idea-by-customID/?customID=' + customID);
        resIdea = await updateDataManagerAPI('PATCH', 'resources/product-ideas/' + resIdea.data['id'] + '/', {
            ratePrior,
            minRates
        });
        if (resProduct.status === 200 && resIdea.status === 200) {
            setLoading(false);
            closeModal('refresh');
        }
    }

    return (
        <Modal show={true} onHide={() => closeModal()} size="lg" className="edit-product-modal">
            <Modal.Header closeButton>
                <Modal.Title>Edit Product {product.customID}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control value={product.title} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Code</Form.Label>
                        <Form.Control value={customID} onChange={e => setCustomID(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Rate Status</Form.Label>
                        <Form.Select onChange={e => setRateStatus(e.target.value)} value={rateStatus}>
                            <option value={null}></option>
                            <option value="RATING">RATING</option>
                            <option value="PAUSED">PAUSED</option>
                            <option value="STOP">STOP</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Sale Status</Form.Label>
                        <Form.Select onChange={e => setSaleStatus(e.target.value)} value={saleStatus}>
                            <option value={null}></option>
                            <option value="FREE">FREE</option>
                            <option value="FREE_TO_PAID">FREE_TO_PAID</option>
                            <option value="PAID">PAID</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="productPrior">
                        <Form.Check type="checkbox" label="Prior" checked={ratePrior} onChange={e => setRatePrior(e.target.checked)} />
                    </Form.Group>
                    {idea && minRates && (
                        <Form.Group className="mb-3">
                            <Form.Label>Min Rates</Form.Label>
                            <Form.Control value={minRates} onChange={e => setMinRates(e.target.value)} />
                        </Form.Group>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {userRole === 'admin' && (
                    <a href={Constant.MANAGER_API_URL + 'admin/free_resources/product/' + product.id + '/change/'} target="_blank" rel="noreferrer">
                        Backend link
                    </a>
                )}
                <div>
                    {loading ? (
                        <Spinner animation="border" variant="dark" />
                    ) : (
                        <Button variant="primary" onClick={update}>Update</Button>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default EditProductModal;