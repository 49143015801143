import { useEffect, useState } from 'react';
import { getDataFromManagerAPI } from '../../../utils/query';
import moment from 'moment';
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import randomColor from 'randomcolor';
import Moment from 'react-moment';
import './styles.scss';

const RatingProductsGraph = ({ products }) => {
    const [ratesByProduct, setRatesByProduct] = useState([]);

    useEffect(() => {
        fetchRateHistory();
    }, [products]);

    const fetchRateHistory = async () => {
        const res = await getDataFromManagerAPI('rates/get-rate-performance-of-prior-ideas/');
        const allRates = res.data;

        if (allRates.length > 0) {
            const minDate = moment(allRates[0]['rated'].substr(0, 10));
            const maxDate = moment(allRates[allRates.length - 1]['rated'].substr(0, 10));

            const ratesByProduct = [];
            for (let d = moment(minDate); d.diff(maxDate, 'days') <= 0; d.add(1, 'days')) {
                const date = d.format('YYYY-MM-DD');
                let data = { date };
                for (let i = 0; i < products.length; i++) {
                    const nbRates = allRates.filter(rate => {
                        const ratedDate = rate['rated'].substr(0, 10);
                        return rate['productData']['customID'] === products[i]['customID']
                            && moment(ratedDate).diff(date) <= 0;
                    }).length;
                    data[products[i].customID] = nbRates;
                }
                ratesByProduct.push(data);
            }
            setRatesByProduct(ratesByProduct);
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip-rating-products-graph">
                    <p className="custom-tooltip-label"><Moment format="DD/MM/YYYY">{label}</Moment></p>
                    <div className="products">
                        {payload.map((product, index) => {
                            const customID = product['dataKey'];
                            const minRates = products.find(p => p['customID'] === customID)['minRates'];
                            return (
                                <span style={{ color: product['stroke'] }} key={index}>
                                    {customID}: {product['payload'][customID]} / {minRates}
                                </span>
                            );
                        })}
                    </div>
                </div>
            )
        }

        return null;
    }

    if (ratesByProduct.length === 0) {
        return null;
    }

    return (
        <div className="rating-products-graph">
            <ResponsiveContainer width="100%" height={500}>
                <ComposedChart width={1000} height={300} data={ratesByProduct}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis yAxisId="left" />
                    {products.map(product => (
                        <Line type="monotone" key={product.id} dataKey={product['customID']} stroke={randomColor({
                            luminosity: 'dark',
                        })} yAxisId="left" />
                    ))}
                    <Tooltip content={<CustomTooltip />} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
}

export default RatingProductsGraph;