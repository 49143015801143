import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getDataFromManagerAPI } from '../../../utils/query';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const ProductSalesHistoryModal = ({idea, hideModal}) => {
    const [salesHistory, setSalesHistory] = useState([]);

    useEffect(() => {
        const fetchSalesHistory = async() => {
            const res = await getDataFromManagerAPI('sales/sale-units/?ideaID=' + idea.id);

            const salesHistory = [];
            for (let i = 0; i < res.data.length; i++) {
                const date = res.data[i].createdAt.substr(0, 10);
                const dateIndex = salesHistory.findIndex(item => item.date === date);
                if (dateIndex === -1) {
                    salesHistory.push({
                        date: date,
                        nbUnitsSold: 1,
                        totalRevenue: res.data[i].revenue,
                    })
                } else {
                    salesHistory[dateIndex].nbUnitsSold += 1;
                    salesHistory[dateIndex].totalRevenue += res.data[i].revenue;
                }
            }
            setSalesHistory(salesHistory);
        }

        fetchSalesHistory();
    }, []);

    return (
        <Modal show={true} onHide={hideModal} size="xl" className="product-sales-history-modal">
            <Modal.Header closeButton>
                <Modal.Title>Product Sales History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart width="100%" height={300} data={salesHistory}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                        <YAxis yAxisId="left" label={{ value: "nbUnitsSold", angle: -90, position: "insideLeft" }} />
                        <YAxis yAxisId="right" orientation="right" label={{ value: "totalRevenue", angle: 90, position: "insideRight" }} />
                        <Tooltip />
                        {/* <Legend onMouseEnter={handleMouseEnter} /> */}
                        <Line yAxisId="left" dataKey="nbUnitsSold" stroke="#ff0000" />
                        <Line yAxisId="right" dataKey="totalRevenue" stroke="#ff00ff" />
                    </LineChart>
                </ResponsiveContainer>
            </Modal.Body>
        </Modal>
    )

}

export default ProductSalesHistoryModal;