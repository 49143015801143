import { Tooltip, XAxis, YAxis, Legend, ResponsiveContainer, ComposedChart, CartesianGrid, Bar, Line } from 'recharts';
import {useState, useEffect} from 'react';
import moment from 'moment';
import Moment from 'react-moment';

const SalesChart = ({report, stores, selectedXAxisType, selectedDataType, dateRange}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (selectedXAxisType === 'day') {
            let data = report;
            const startDate = moment(dateRange.startDate);
            const endDate = moment(dateRange.endDate);
            for (let m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
                if (m.day() === 1) {
                    // is monday, calculate the avg sales of the week (monday => sunday)
                    let lastDayOfTheWeek = m.clone();
                    lastDayOfTheWeek.add(6, 'days');
                    if (lastDayOfTheWeek > endDate) {
                        lastDayOfTheWeek = endDate;
                    }
                    const salesOfTheWeek = report.filter(dayData => {
                        const fullDate = moment(dayData.day);
                        return fullDate.isBetween(m, lastDayOfTheWeek) || fullDate.isSame(m) || fullDate.isSame(lastDayOfTheWeek);
                    });
                    let revenueOfTheWeek = 0, nbUnitSoldsOfTheWeek = 0;
                    salesOfTheWeek.forEach(dayData => {
                        stores.forEach(store => {
                            revenueOfTheWeek += dayData[store.id + '_revenue'];
                            nbUnitSoldsOfTheWeek += dayData[store.id + '_units'];
                        })
                    });
                    data = data.map(item => {
                        if (moment(item.day).isBefore(m) || moment(item.day).isAfter(lastDayOfTheWeek)) {
                            return item;
                        }
                        return {...item, dailySaleOfTheWeek: parseInt(revenueOfTheWeek / 7), dailyUnitSolds: parseInt(nbUnitSoldsOfTheWeek / 7)};
                    });
                }
            }
            setData(data);
        }
    }, [report]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let total = parseInt(payload.reduce((callback, item) => {
                if (item.dataKey !== 'dailySaleOfTheWeek' && item.dataKey !== 'dailyUnitSolds') {
                    return callback + item.value;
                }
                return callback;
            }, 0));
            return (
                <div className='custom-tooltip'>
                    <p className='custom-tooltip-label'>
                        <Moment format="ddd DD/MM" parse="YYYY-MM-DD">{label}</Moment>
                    </p>
                    {payload.map((item, index) => {
                        if (item.dataKey === 'dailySaleOfTheWeek') {
                            return <div><strong>Daily Sale of the Week: ${item.value}</strong></div>;
                        } else if (item.dataKey === 'dailyUnitSolds') {
                            return <div>Daily Units of the Week: ${item.value}</div>;
                        }
                        const storeID = item.name.split('_')[0];
                        const store = stores.find(s => s.id.toString() === storeID);
                        if (store === undefined) {
                            return <div></div>;
                        }
                        return (
                            <div key={index}>{store.name}: {item.value}{selectedDataType === 'earnings' ? '$' : ''}</div>
                        );
                    })}
                    <div><strong>Total: {total}{selectedDataType === 'earnings' ? '$' : ''}</strong></div>
                </div>
            );
        }

        return null;
    }

    return (
        <ResponsiveContainer width="100%" height={500}>
            <ComposedChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={selectedXAxisType} />
                <YAxis />
                <Tooltip cursor={{fill: 'rgba(0, 0, 0, 0)'}} content={<CustomTooltip />} />
                <Legend />
                {stores.map(store => (
                    <Bar key={store.id} type='monotone' dataKey={selectedDataType === 'earnings' ? (store.id + '_revenue') : (store.id + '_sales')}
                        stroke="#8884d8" stackId="1" fill={store.color} />
                ))}
                {selectedDataType === 'earnings' ? (
                    <Line stroke="#000000" dataKey='dailySaleOfTheWeek' />
                ) : (
                    <Line stroke="#000000" dataKey='dailyUnitSolds' />
                )}
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default SalesChart;