import { useEffect, useState } from 'react';
import { Container, Form, Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import './styles.scss';
import { getDataFromManagerAPI, updateDataManagerAPI } from '../../utils/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const TestimonialsPage = ({ }) => {
    const [loading, setLoading] = useState(false);
    const [selectedProductIdea, setSelectedProductIdea] = useState();
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        if (selectedProductIdea) {
            fetchReviewsByCustomID();
        }
    }, [selectedProductIdea]);

    const fetchReviewsByCustomID = async () => {
        const res = await getDataFromManagerAPI('rates/reviews/?customID=' + selectedProductIdea['customID']);
        setReviews(res.data);

    }

    const loadOptions = (inputValue, callback) => {
        if (inputValue.length > 0) {
            getDataFromManagerAPI('resources/search-product-idea-by-custom-id/?customID=' + inputValue + '&additionalFields=storeData').then(res => {
                callback(res.data.map(idea => ({
                    label: idea['customID'] + '. ' + idea['name'],
                    value: idea
                })));
            });
        }
    }

    const toggleStarReview = async (review) => {
        await updateDataManagerAPI('PATCH', 'rates/reviews/' + review.id + '/', {
            star: !review.star
        });
        setReviews(reviews.map(r => {
            if (r.id !== review.id) {
                return r;
            }
            return { ...r, star: !r.star };
        }));
    }

    return (
        <Container className="testimonials-page">
            <h2>Testimonials</h2>
            <Form>
                <Form.Group className="mb-3" controlId="product-idea-select">
                    <Form.Label>Choose Product</Form.Label>
                    <AsyncSelect loadOptions={loadOptions} defaultOptions
                        onChange={selectValue => setSelectedProductIdea(selectValue['value'])} />
                </Form.Group>
            </Form>
            <div className="reviews">
                <Table bordered hover striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Review</th>
                            <th>Source</th>
                            <th>Star</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviews.map((review, index) => (
                            <tr key={review.id}>
                                <td>{index + 1}</td>
                                <td>{review.content}</td>
                                <td>{review.source}</td>
                                <td className="star-cell">
                                    <FontAwesomeIcon icon={faStar} className={'star ' + (review.star ? 'active' : 'inactive')}
                                        onClick={() => toggleStarReview(review)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Container>
    )
}

export default TestimonialsPage;