import { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { DateRangePicker } from 'react-date-range';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';
import moment from 'moment';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { setEndDate, setStartDate } from '../filtersSlice';

const FilterByDateRange = ({ defaultDateRange }) => {
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState([
        {
            startDate: defaultDateRange.startDate,
            endDate: defaultDateRange.endDate,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        dispatch(setStartDate(moment(defaultDateRange.startDate).format('YYYY-MM-DD')));
        dispatch(setEndDate(moment(defaultDateRange.endDate).format('YYYY-MM-DD')));
    }, []);

    return (
        <div className="filter-by-date-range">
            <OverlayTrigger trigger="click" placement="bottom"
                overlay={
                    <Popover>
                        <Popover.Body>
                            <DateRangePicker
                                ranges={dateRange}
                                onChange={item => setDateRange([item.selection])}
                            />
                            <Button variant='success' onClick={() => {
                                dispatch(setStartDate(moment(dateRange[0].startDate).format('YYYY-MM-DD')));
                                dispatch(setEndDate(moment(dateRange[0].endDate).format('YYYY-MM-DD')));
                            }}>Apply</Button>
                        </Popover.Body>
                    </Popover>
                }>
                <div className='date-range'>
                    <Moment format="DD/MM/YYYY">
                        {dateRange[0].startDate}
                    </Moment>
                    -
                    <Moment format="DD/MM/YYYY">
                        {dateRange[0].endDate}
                    </Moment>
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default FilterByDateRange;