export default class Constant {
    static MANAGER_API_URL = 'https://manage-api.k12worksheet.com/';
    static STUDIO_API_URL = 'https://studio-api.k12worksheet.com/';
    static STUDIO_URL = 'https://resources.k12worksheet.com/';
    static TPTSELLER_API_URL = 'https://tptseller-api.k12worksheet.com/';

    static LOCAL_STORAGE_PREFIX = '_bee1203_manager_';
    static LOCAL_STORAGE_TOKEN = this.LOCAL_STORAGE_PREFIX + 'token';
    static LOCAL_STORAGE_ROLE = this.LOCAL_STORAGE_PREFIX + 'role';
    static LOCAL_STORAGE_USER_EMAIL = this.LOCAL_STORAGE_PREFIX + 'user_email';

    static STUDIO_API_TOKEN = 'Token 8318eb3b66364515451ffaf57021bdab3c21ed16';
    static TPTSELLER_API_TOKEN = 'Token c232798101d7bd3e51c7937fc45c747646db231b';

    static ROUTE_PERMISSIONS = {
        '/': ['uploader', 'admin'],
        '/product-status': ['admin', 'uploader'],
        '/product-sales': ['admin'],
        '/revenue': ['admin'],
        '/rating-products': ['admin'],
        '/bot-performance': ['admin'],
        '/import-reviews': ['admin', 'uploader'],
        '/testimonials': ['admin', 'uploader'],
        '/product-stats': ['admin'],
    };


    static GRADES = ['PreK', 'Kindergarten', 'Homeschool', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
}