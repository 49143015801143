import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faPenToSquare, faDownload, faWaveSquare, faLink } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import EditProductModal from '../edit-product-modal';
import RatePerformanceByProductModal from '../rate-performance-by-product-modal';

import './styles.scss';
import Constant from '../../../Constant';

const ProductLinks = ({ product, refreshAllProducts }) => {
    const [editingProduct, setEditingProduct] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        setUserRole(localStorage.getItem(Constant.LOCAL_STORAGE_ROLE));
    }, []);

    return (
        <div className="product-links-container">
            <a href={product.url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
            {product.customID && (
                <a href={Constant.STUDIO_URL + 'downloads?selectedProduct=' + product.customID} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faDownload} />
                </a>
            )}
            {userRole === 'admin' && product.rateStatus === 'RATING' && (
                <span onClick={() => setSelectedProduct(product)}>
                    <FontAwesomeIcon icon={faWaveSquare} />
                </span>
            )}
            <span onClick={() => setEditingProduct(product)} title="Edit">
                <FontAwesomeIcon icon={faPenToSquare} />
            </span>
            <a href={'https://resources.k12worksheet.com/to-upload-products?selectedStoreID=' + product.store + '&searchKeyword=' + product.customID} target="_blank">
                <FontAwesomeIcon icon={faLink} />
            </a>
            {editingProduct !== null && (
                <EditProductModal product={editingProduct} closeModal={action => {
                    setEditingProduct(null);
                    if (action === 'refresh') {
                        refreshAllProducts();
                    }
                }} />
            )}
            {selectedProduct !== null && (
                <RatePerformanceByProductModal product={selectedProduct} closeModal={action => {
                    setSelectedProduct(null);
                }} />
            )}
        </div>
    )
}

export default ProductLinks;