import ProductLinks from '../../../pages/product-status/product-links';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning, faCrown } from '@fortawesome/free-solid-svg-icons';

import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useState } from 'react';
import Notification from '../../../utils/notification';

import './styles.scss';
import { updateDataManagerAPI } from '../../../utils/query';

const AllProductsTable = ({ products, productsByCode, showGradesColumn, fetchProducts, productRatings, refreshAllProducts, setLoading }) => {

    const [notification, setNotification] = useState(null);

    const updateProductRateStatus = async (productID, val) => {
        setLoading(true);
        await updateDataManagerAPI('PATCH', 'resources/products/' + productID + '/', { rateStatus: val });
        await refreshAllProducts();
        setLoading(false);
    }

    return (
        <div className="all-product-table">
            <div className="table-prefix">
                <div className="table-name">ON TPT</div>
                <div className="table-nb-lines">
                    {products.length} results
                </div>
            </div>
            <Table bordered hover className="all-products-table">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th colSpan={4 - (showGradesColumn ? 0 : 1)}>Free</th>
                        <th colSpan={5 - (showGradesColumn ? 0 : 1)}>Paid</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>Thumbnail</th>
                        <th>Title</th>
                        <th>Rate</th>
                        {showGradesColumn && <th>Grades</th>}
                        <th>Thumbnail</th>
                        <th>Title</th>
                        <th>Rate</th>
                        {showGradesColumn && <th>Grades</th>}
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(code => {
                        const freeProducts = productsByCode[code]['free'];
                        const paidProducts = productsByCode[code]['paid'];
                        return (
                            <tr key={code}>
                                <td className="product-code">{code}</td>
                                <td className="thumbnail">
                                    {freeProducts.map(p => (
                                        <img key={'thumbnail-' + p.id} src={p.thumbnail} alt="product thumbnail" />
                                    ))}
                                </td>
                                <td className="title">
                                    {freeProducts.map(p => (
                                        <div key={'title-' + p.id}>
                                            {p.title}
                                            <ProductLinks product={p} refreshAllProducts={fetchProducts} />
                                        </div>
                                    ))}
                                </td>
                                <td className="rate-status">
                                    {freeProducts.map(p => (
                                        <div key={'rate-' + p.id}>
                                            <div className={p.rateStatus}>
                                                <select className="form-select select-rate-status" onChange={e => updateProductRateStatus(p.id, e.target.value)} value={p.rateStatus}>
                                                    <option></option>
                                                    <option value="RATING">RATING</option>
                                                    <option value="PAUSED">PAUSED</option>
                                                    <option value="STOP">STOP</option>
                                                </select>
                                                <span>{productRatings[p.tptID]} / {p.minRates}</span>
                                                {p.ratePrior && (
                                                    <FontAwesomeIcon icon={faCrown} className="rating-prior" />
                                                )}
                                            </div>
                                            {p.rateStatus === null && (
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => {
                                                        return (
                                                            <Tooltip id="danger-tooltip" {...props}>
                                                                Status problem
                                                            </Tooltip>
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon className="danger-icon" icon={faWarning} />
                                                </OverlayTrigger>
                                            )}
                                            {p.minRates && productRatings[p.tptID] >= p.minRates
                                                && productRatings[paidProducts[0].tptID] < productRatings[p.tptID] && (
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={(props) => {
                                                            return (
                                                                <Tooltip id="warning-tooltip" {...props}>
                                                                    Over Rate
                                                                </Tooltip>
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon className="warning-icon" icon={faWarning} />
                                                    </OverlayTrigger>
                                                )}
                                        </div>
                                    ))}
                                </td>
                                {showGradesColumn && (
                                    <td className="grades">
                                        {freeProducts.map(p => (
                                            <div key={'grade-' + p.id}>{p.grades}</div>
                                        ))}
                                    </td>
                                )}
                                <td className="thumbnail">
                                    {paidProducts.map(p => (
                                        <img key={'thumbnail-' + p.id} src={p.thumbnail} alt="product thumbnail" />
                                    ))}
                                </td>
                                <td className="title">
                                    {paidProducts.map(p => (
                                        <div key={'title-' + p.id}>
                                            {p.title}
                                            <ProductLinks product={p} refreshAllProducts={fetchProducts} />
                                        </div>
                                    ))}
                                </td>
                                <td className="rate-status">
                                    {paidProducts.map(p => (
                                        <div key={'rate-' + p.id}>
                                            <div className={p.rateStatus}>
                                                {p.rateStatus === 'STOP' ? (
                                                    <>
                                                        <div>STOP</div>
                                                        <span>{productRatings[p.tptID]} / {p.minRates}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>{p.rateStatus}</div>
                                                        <span>{productRatings[p.tptID]} / {p.minRates}</span>
                                                    </>
                                                )}
                                                {p.ratePrior && (
                                                    <FontAwesomeIcon icon={faCrown} className="rating-prior" />
                                                )}
                                            </div>
                                            {(
                                                (p.rateStatus === 'RATING' && p.saleStatus === 'PAID') ||
                                                (p.rateStatus === 'STOP' && p.saleStatus !== 'PAID') ||
                                                p.rateStatus === null) && (
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={(props) => {
                                                            return (
                                                                <Tooltip id="danger-tooltip" {...props}>
                                                                    Status problem
                                                                </Tooltip>
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon className="danger-icon" icon={faWarning} />
                                                    </OverlayTrigger>
                                                )}
                                            {(p.rateStatus !== 'STOP' && p.minRates && productRatings[p.tptID] >= p.minRates) && (
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props) => {
                                                        return (
                                                            <Tooltip id="warning-tooltip" {...props}>
                                                                Over Rate
                                                            </Tooltip>
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon className="warning-icon" icon={faWarning} />
                                                </OverlayTrigger>
                                            )}
                                        </div>
                                    ))}
                                </td>
                                {showGradesColumn && (
                                    <td className="grades">
                                        {paidProducts.map(p => (
                                            <div key={'grade-' + p.id}>{p.grades}</div>
                                        ))}
                                    </td>
                                )}
                                <td className="price">
                                    {paidProducts.map(p => (
                                        <div key={p.id}>
                                            <div>
                                                {p.discountPrice !== null ? (
                                                    <>
                                                        <span className="price">${p.discountPrice}</span>
                                                        <span className="original-price">(<s>${p.price}</s>, -{parseInt((p.price - p.discountPrice) / p.price * 100)}%)</span>
                                                    </>
                                                ) : (
                                                    <span className="price">{p.price !== null ? '$' + p.price : 'FREE'}</span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {notification !== null && (
                <Notification title={notification} closeNotification={() => setNotification(null)} />
            )}
        </div>
    );
}

export default AllProductsTable;