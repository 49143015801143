import { useEffect, useState } from 'react';
import FilterByStore from '../../../components/filters/store';
import FilterBySearch from '../../../components/filters/search';
import { Form, Tab, Tabs } from 'react-bootstrap';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

import './styles.scss';
import { getDataFromManagerAPI, getDataFromTPTSellerAPI } from '../../../utils/query';
import RatePerformanceByProductModal from '../../product-status/rate-performance-by-product-modal';
import RatingProductsTable from '../../../components/rating-products/table';
import RatingProductsGraph from '../../../components/rating-products/graph';

const RatingProductsPage = ({ }) => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);
    const [loading, setLoading] = useState(false);

    const [ratingProducts, setRatingProducts] = useState([]);
    const [pausedProducts, setPausedProducts] = useState([]);
    const [selectedRateStatus, setSelectedRateStatus] = useState('RATING');
    const [selectedSaleStatus, setSelectedSaleStatus] = useState('all');
    const [selectedPrior, setSelectedPrior] = useState('all');

    const [productRatings, setProductRatings] = useState({});
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        fetchProducts();
        fetchProductRatings();
    }, []);

    const fetchProducts = async() => {
        setLoading(true);
        await fetchRatingProducts();
        await fetchPausedProducts();
        setLoading(false);
    }

    const fetchRatingProducts = async () => {
        const res = await getDataFromManagerAPI('resources/products/?rateStatus=RATING&additionalFields=minRates,ratePrior,downloadPrior');
        setRatingProducts(res.data);
    }

    const fetchPausedProducts = async () => {
        const res = await getDataFromManagerAPI('resources/products/?rateStatus=PAUSED');
        setPausedProducts(res.data);
    }

    const fetchProductRatings = async () => {
        const res = await getDataFromTPTSellerAPI('rankchecker/get-last-product-ranks/');
        const productRatings = {};
        for (let i = 0; i < res.data.length; i++) {
            const rank = res.data[i];
            const tptID = rank.productData.tptID;

            productRatings[tptID] = rank.nbRatings;
        }
        setProductRatings(productRatings);
    }

    const getFilteredProducts = () => {
        let filteredProducts;
        if (selectedRateStatus === 'RATING') {
            filteredProducts = ratingProducts;
        } else {
            filteredProducts = pausedProducts;
        }
        filteredProducts = filteredProducts.filter(p => {
            if (selectedStoreID !== null && !isNaN(selectedStoreID) && p.store !== selectedStoreID) {
                return false;
            }
            if (searchKeyword !== '' && searchKeyword !== undefined &&
                p.title && p.title.toLowerCase().indexOf(searchKeyword.toLowerCase()) === -1 &&
                p.customID && p.customID.toLowerCase().indexOf(searchKeyword.toLowerCase()) === -1) {
                return false;
            }
            if (selectedPrior !== 'all' && ((selectedPrior === 'yes' && !p.ratePrior) || (selectedPrior === 'no' && p.ratePrior))) {
                return false;
            }
            if (selectedSaleStatus !== 'all' && selectedSaleStatus !== p.saleStatus) {
                return false;
            }
            return true;
        });
        return filteredProducts;
    }

    return (
        <Container className="rating-products-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore hasAllOption={true} />
                        <FilterBySearch />
                        <Form.Select onChange={e => setSelectedRateStatus(e.target.value)} value={selectedRateStatus} className="status-filter">
                            <option value="RATING">RATING</option>
                            <option value="PAUSED">PAUSED</option>
                        </Form.Select>
                        <Form.Select onChange={e => setSelectedSaleStatus(e.target.value)} value={selectedSaleStatus} className="status-filter">
                            <option value="all">ALL</option>
                            <option value="PAID">PAID</option>
                            <option value="FREE">FREE</option>
                        </Form.Select>
                        <Form.Select onChange={e => setSelectedPrior(e.target.value)} value={selectedPrior} className="status-filter">
                            <option value="all">All Products</option>
                            <option value="yes">Prior Only</option>
                        </Form.Select>
                    </div>
                </div>
                <div className="filter-right">
                    <div><strong>Total: {ratingProducts.length} rating products</strong></div>
                    {/* <div><strong>Quota: {nbRatingProductsQuota} rating products</strong></div> */}
                    <div>Filter: {getFilteredProducts().length} products</div>
                </div>
            </div>

            <Tabs defaultActiveKey="graph" id="rating-products-tabs" className="mb-3">
                <Tab eventKey="graph" title="Graph">
                    <RatingProductsGraph
                        products={getFilteredProducts()} />
                </Tab>
                <Tab eventKey="table" title="Table">
                    <RatingProductsTable
                        productRatings={productRatings}
                        products={getFilteredProducts()}
                        selectedRateStatus={selectedRateStatus}
                        ratingProducts={ratingProducts}
                        pausedProducts={pausedProducts}
                        fetchRatingProducts={fetchRatingProducts}
                        setRatingProducts={setRatingProducts}
                        setPausedProducts={setPausedProducts}
                        setSelectedProduct={setSelectedProduct} />
                </Tab>
            </Tabs>

            {selectedProduct !== null && (
                <RatePerformanceByProductModal product={selectedProduct} closeModal={action => {
                    setSelectedProduct(null);
                }} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default RatingProductsPage;