import { useEffect, useState } from 'react';
import { Container, Form, Row, Col, Spinner, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import AsyncSelect from 'react-select/async';
import { getDataFromManagerAPI, updateDataManagerAPI } from '../../utils/query';

const ImportReviewsPage = ({ }) => {
    const [loading, setLoading] = useState(false);
    const [selectedProductIdea, setSelectedProductIdea] = useState();
    const [chatGPTReviews, setChatGPTReviews] = useState(null);
    const [addingReviews, setAddingReviews] = useState([]);

    useEffect(() => {
        const selectedProduct = (new URLSearchParams(window.location.search)).get('selectedProduct');
        if (selectedProduct) {
            getDataFromManagerAPI('resources/search-product-idea-by-custom-id/?customID=' + selectedProduct + '&additionalFields=storeData').then(res => {
                if (res.data.length > 0) {
                    setSelectedProductIdea(res.data[0]);
                }
            });
        }
    }, []);

    useEffect(() => {
        setAddingReviews([]);
        setChatGPTReviews(null);
    }, [selectedProductIdea]);

    const importReviews = async () => {
        if (selectedProductIdea && addingReviews.length > 0) {
            setLoading(true);
            await updateDataManagerAPI('POST', 'rates/import-reviews/', {
                source: 'chatgpt',
                customID: selectedProductIdea.customID,
                reviews: addingReviews.filter(r => r.length >= 50),
            });
            alert('Reviews imported successfully');
            setAddingReviews([]);
            setChatGPTReviews(null);
            setLoading(false);
        }
    }

    const deleteAddingReview = indexToRemove => {
        setAddingReviews([...addingReviews.slice(0, indexToRemove), ...addingReviews.slice(indexToRemove + 1)]);
    }

    const formatChatGPTReviews = () => {
        const reviews = chatGPTReviews.split('\n').map(r => {
            r = r.trim();
            if (r[0] === '"') {
                r = r.slice(1);
            }
            if (r[r.length - 1] === '"') {
                r = r.slice(0, r.length - 1);
            }
            return r;
        });
        setAddingReviews(reviews.filter((r, pos) => reviews.indexOf(r) === pos && r.length > 50));
    }

    const loadOptions = (inputValue, callback) => {
        if (inputValue.length > 0) {
            getDataFromManagerAPI('resources/search-product-idea-by-custom-id/?customID=' + inputValue + '&additionalFields=storeData').then(res => {
                callback(res.data.map(idea => ({
                    label: idea['customID'] + '. ' + idea['name'],
                    value: idea
                })));
            });
        }
    }

    return (
        <Container className="import-reviews-page">
            <h2>Import Reviews</h2>
            <Form>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="product-idea-select">
                            <Form.Label>Choose Product</Form.Label>
                            <AsyncSelect loadOptions={loadOptions} defaultOptions
                                onChange={selectValue => setSelectedProductIdea(selectValue['value'])} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="tpt-product-id-input">
                            <Form.Label>Chat GPT Reviews</Form.Label>
                            <div>
                                Write me 60 quite good reviews that are more than 50 characters about the worksheet: ""
                            </div>
                            <Form.Control as="textarea" rows={5} value={chatGPTReviews} onChange={e => setChatGPTReviews(e.target.value)} />
                        </Form.Group>
                        <Button onClick={formatChatGPTReviews} size="sm">Format</Button>
                    </Col>
                </Row>
                <Row>
                    {selectedProductIdea && addingReviews.length > 0 && (
                        <Button className="mt-4 import-btn" onClick={importReviews}>Import</Button>
                    )}
                </Row>
                <Row>
                    <Col>
                        <div className="adding-reviews">
                            <ListGroup>
                                {addingReviews.map((review, index) => (
                                    <ListGroup.Item key={index} className={(review.length > 101 ? 'testimonial' : '') + ' ' + (review.length < 50 ? 'wrong' : '')}>
                                        <span>{index + 1}. {review}</span>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => deleteAddingReview(index)} />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Form>
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ImportReviewsPage;