import { useEffect, useState } from 'react';
import { Container, Form, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import FilterByStore from '../../components/filters/store';
import FilterByDateRange from '../../components/filters/datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDataFromManagerAPI } from '../../utils/query';
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const ProductStatsPage = ({ }) => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);

    const [ideas, setIdeas] = useState([]);
    const [selectedCustomID, setSelectedCustomID] = useState(null);
    const [stats, setStats] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedStoreID) {
            fetchIdeasByStore();
        }
    }, [selectedStoreID]);

    useEffect(() => {
        if (selectedStoreID && startDate && endDate) {
            fetchStats();
        }
    }, [selectedStoreID, startDate, endDate]);

    useEffect(() => {
        if (selectedCustomID) {
            getChartData();
        }
    }, [selectedCustomID, stats]);

    const fetchIdeasByStore = async () => {
        setSelectedCustomID(null);
        const res = await getDataFromManagerAPI('resources/product-ideas/?store=' + selectedStoreID);
        setIdeas(res.data);
    }

    const fetchStats = async () => {
        setLoading(true);
        const res = await getDataFromManagerAPI('sales/product-statistics/?store=' + selectedStoreID + '&startDate=' + startDate + '&endDate=' + endDate);
        setStats(res.data);
        setLoading(false);
    }

    const getChartData = () => {
        let data = [];

        for (let d = moment(startDate); d.isSameOrBefore(moment(endDate)); d.add(1, 'days')) {
            const date = d.format('YYYY-MM-DD');
            const items = stats.filter(item => item['date'] === date && item['customID'] === selectedCustomID);
            if (items.length > 0) {
                const itemsData = { date };
                for (let i = 0; i < items.length; i++) {
                    itemsData[items[i]['saleStatus'] + '_views'] = items[i]['nbViews'];
                    itemsData[items[i]['saleStatus'] + '_sales'] = items[i]['nbSales'];
                }
                data.push(itemsData);
            }
        }

        setData(data);
    }

    return (
        <Container>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore showMetaData={false} />
                        <FilterByDateRange defaultDateRange={{
                            startDate: moment().startOf('month').toDate(),
                            endDate: moment().toDate()
                        }} />
                        <Form.Select onChange={e => setSelectedCustomID(e.target.value)} value={selectedCustomID}>
                            <option></option>
                            {ideas.map(idea => (
                                <option value={idea.customID} key={idea.id}>{idea.customID}. {idea.name}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="filter-right">
                </div>
            </div>

            <div>
                <Tabs defaultActiveKey="graph" id="product-stats-tabs">
                    <Tab eventKey="graph" title="Graph">
                        {data.length > 0 && (
                            <ResponsiveContainer width="100%" height={500}>
                                <ComposedChart width={1000} height={300} data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis yAxisId="left" />
                                    <YAxis yAxisId="right" orientation="right" />
                                    <Line type="monotone" dataKey="FREE_views" stroke="#04cf44" yAxisId="left" />
                                    <Line type="monotone" dataKey="PAID_views" stroke="#3838e8" yAxisId="right" />
                                    <Line type="monotone" dataKey="PAID_sales" stroke="#f51d11" yAxisId="right" />
                                    <Legend />
                                </ComposedChart>
                            </ResponsiveContainer>
                        )}
                    </Tab>
                    <Tab eventKey="table" title="Table">
                        <Table bordered hover striped>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Free Views</th>
                                    <th>PAID Views</th>
                                    <th>Sales</th>
                                    <th>Conversion Rate (Sales / PAID views)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item['date']}</td>
                                        <td>{item['FREE_views']}</td>
                                        <td>{item['PAID_views']}</td>
                                        <td>{item['PAID_sales']}</td>
                                        <td>
                                            {item['PAID_views'] > 0 && (
                                                <span>
                                                    {parseInt(item['PAID_sales'] / item['PAID_views'] * 100)}%
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            </div>
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="dark" />
                </div>
            )}
        </Container>
    )
}

export default ProductStatsPage;