import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import LoginPage from './pages/login';
import HomePage from './pages/home';
import ProductStatusPage from './pages/product-status';
import ProductSalesPage from './pages/sales/product-sales';
import RevenuePage from './pages/sales/revenue';
import RatingProductsPage from './pages/bot/rating-products';
import BotPerformancePage from './pages/bot/bot-performance';
import ImportReviewsPage from './pages/import-reviews';
import TestimonialsPage from './pages/testimonials';
import ProductStatsPage from './pages/product-stats';

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        component={LoginPage}
                    />
                    <AuthRoute
                        exact
                        path="/"
                        component={HomePage}
                    />
                    <AuthRoute
                        exact
                        path="/product-status"
                        component={ProductStatusPage}
                    />
                    <AuthRoute
                        exact
                        path="/product-sales"
                        component={ProductSalesPage}
                    />
                    <AuthRoute
                        exact
                        path="/revenue"
                        component={RevenuePage}
                    />
                    <AuthRoute
                        exact
                        path="/rating-products"
                        component={RatingProductsPage}
                    />
                    <AuthRoute
                        exact
                        path="/bot-performance"
                        component={BotPerformancePage}
                    />
                    <AuthRoute
                        exact
                        path="/import-reviews"
                        component={ImportReviewsPage}
                    />
                    <AuthRoute
                        exact
                        path="/testimonials"
                        component={TestimonialsPage}
                    />
                    <AuthRoute
                        exact
                        path="/product-stats"
                        component={ProductStatsPage}
                    />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
