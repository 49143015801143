import './styles.scss';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Notification = ({title, closeNotification}) => {
    return (
        <ToastContainer className="p-3" position="bottom-end">
            <Toast onClose={closeNotification} delay={3000} autohide>
                <Toast.Header>
                    <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
                    <strong className="me-auto">{title}</strong>
                </Toast.Header>
            </Toast>
        </ToastContainer>
    )
}

export default Notification;