import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import './styles.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import FilterByStore from '../../../components/filters/store';
import { getDataFromManagerAPI, updateDataTPTSellerAPI } from '../../../utils/query';
import FilterByDateRange from '../../../components/filters/datepicker';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import ProductSalesHistoryModal from '../../../components/product-sales/product-sales-history-modal';

const ProductSalesPage = () => {
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [ideas, setIdeas] = useState([]);
    const [conversionRates, setConversionRates] = useState([]);
    const [totalUnitsSoldByStore, setTotalUnitsSoldByStore] = useState({});
    const [totalRevenueByStore, setTotalRevenueByStore] = useState({});
    const [keyword, setKeyword] = useState(null);
    const [ranks, setRanks] = useState({});
    const [ideaInSalesHistoryModal, setIdeaInSalesHistoryModal] = useState(null);

    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        const res = await getDataFromManagerAPI('resources/stores/');
        setStores(res.data);
    }

    useEffect(() => {
        if (startDate && endDate) {
            // fetchSaleSummary();
            // fetchConversionRates();
        }
    }, [selectedStoreID, startDate, endDate]);

    const sortDataBy = attr => {
        let summary = ideas.slice(0);
        let sortOrder = 1;
        if (attr === 'units') {
            sortOrder = summary[0].nbUnitsSold > summary[summary.length - 1].nbUnitsSold ? 1 : -1;
        } else if (attr === 'revenue') {
            sortOrder = summary[0].totalRevenue > summary[summary.length - 1].totalRevenue ? 1 : -1;
        } else if (attr === 'conversionRate') {
            sortOrder = conversionRates[summary[0].id]['conversionRate'] > conversionRates[summary[summary.length - 1].id]['conversionRate'] ? 1 : -1;
        } else {
            sortOrder = conversionRates[summary[0].id]['totalViews'] > conversionRates[summary[summary.length - 1].id]['totalViews'] ? 1 : -1;
        }

        summary.sort((a, b) => {
            if (attr === 'units') {
                return (a.nbUnitsSold - b.nbUnitsSold) * sortOrder;
            } else if (attr === 'revenue') {
                return (a.totalRevenue - b.totalRevenue) * sortOrder;
            } else if (attr === 'conversionRate') {
                return (conversionRates[a.id]['conversionRate'] - conversionRates[b.id]['conversionRate']) * sortOrder;
            }
            return (conversionRates[a.id]['totalViews'] - conversionRates[b.id]['totalViews']) * sortOrder;
        });
        setIdeas(summary);
        // let ps = products.filter(product => product.saleStatus === 'PAID').slice(0);
        // if (saleRates.find(p => p.product === ps[0].id)[attr] < saleRates.find(p => p.product === ps[ps.length - 1].id)[attr]) {
        //     ps.sort((a, b) => saleRates.find(p => p.product === b.id)[attr] - saleRates.find(p => p.product === a.id)[attr]);
        // } else {
        //     ps.sort((a, b) => saleRates.find(p => p.product === a.id)[attr] - saleRates.find(p => p.product === b.id)[attr]);
        // }
        // ps = ps.concat(products.filter(product => product.saleStatus !== 'PAID').slice(0));
        // setProducts(ps);
    }

    const fetchRanks = async (tptIDs) => {
        const resRanks = await updateDataTPTSellerAPI('POST', 'rankchecker/get-last-store-ranks-by-tptIDs/', {
            tptIDs
        });
        setRanks(resRanks.data);
    }

    const filter = async () => {
        setLoading(true);
        let url = 'resources/product-ideas/?additionalFields=sales,paidProductInfo';
        if (keyword && keyword.length > 0) {
            url += '&keyword=' + keyword;
        }
        if (startDate && endDate) {
            url += '&startDate=' + startDate + '&endDate=' + endDate;
        }
        if (selectedStoreID) {
            url += '&store=' + selectedStoreID;
        }
        const res = await getDataFromManagerAPI(url);
        const ideas = res.data;
        setIdeas(ideas);

        let totalUnitsSoldByStore = {};
        let totalUnitsSold = 0;
        let totalRevenueByStore = {};
        let totalRevenue = 0;
        for (let i = 0; i < ideas.length; i++) {
            const p = ideas[i];
            if (totalUnitsSoldByStore[p.store] === undefined) {
                totalUnitsSoldByStore[p.store] = {inDateRange: 0, allTime: 0};
                totalRevenueByStore[p.store] = {inDateRange: 0, allTime: 0};
            }
            totalUnitsSoldByStore[p.store]['inDateRange'] += p.sales.nbUnitsSoldInDateRange;
            totalUnitsSoldByStore[p.store]['allTime'] += p.sales.nbUnitsSoldAllTime;
            totalUnitsSold += p.sales.nbUnitsSoldInDateRange;
            totalRevenueByStore[p.store]['inDateRange'] += p.sales.totalRevenueInDateRange;
            totalRevenueByStore[p.store]['allTime'] += p.sales.totalRevenueAllTime;
            totalRevenue += p.sales.totalRevenueInDateRange;
        }
        totalUnitsSoldByStore['all'] = {inDateRange: totalUnitsSold};
        totalRevenueByStore['all'] = {inDateRange: totalRevenue};
        setTotalUnitsSoldByStore(totalUnitsSoldByStore);
        setTotalRevenueByStore(totalRevenueByStore);

        setLoading(false);
        fetchRanks(ideas.filter(idea => idea.paidProductInfo).map(idea => idea.paidProductInfo.tptID));
    }

    if (stores.length === 0) {
        return null;
    }

    return (
        <Container className="products-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <Form.Group controlId="keyword">
                            <Form.Label>Keyword</Form.Label>
                            <Form.Control type="text" value={keyword} onChange={e => setKeyword(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="dateRange">
                            <Form.Label>Date Range</Form.Label>
                            <FilterByDateRange defaultDateRange={{
                                startDate: moment().startOf('month').toDate(),
                                endDate: moment().toDate()
                            }} />
                        </Form.Group>
                        <Form.Group controlId="filterByStore">
                            <Form.Label>Store</Form.Label>
                            <FilterByStore hasAllOption={true} />
                        </Form.Group>
                        <Button onClick={filter}>Filter</Button>
                    </div>
                </div>
                <div className="filter-right">
                    <div>
                    </div>
                </div>
            </div>
            <div className='result'>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Index</th>
                            <th>Store</th>
                            <th>Code</th>
                            <th>Thumbnail</th>
                            <th>Title</th>
                            <th>Ratings</th>
                            <th onClick={() => sortDataBy('units')}>Units</th>
                            <th onClick={() => sortDataBy('revenue')}>Revenue ($)</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ideas.filter(idea => {
                            if (selectedStoreID === null) {
                                return true;
                            }

                            return idea.store === selectedStoreID;
                        })
                            .map((idea, pIndex) => {
                                return (
                                    <tr key={pIndex}>
                                        <td>{pIndex + 1}</td>
                                        <td>Store {idea.store}</td>
                                        <td>{idea.customID}</td>
                                        <td>
                                            <img src={idea?.paidProductInfo.thumbnail} />
                                        </td>
                                        <td>
                                            <a href={idea?.paidProductInfo.url} target="_blank">
                                                {idea?.paidProductInfo.title}
                                            </a>
                                        </td>
                                        <td>
                                            <div>{ranks[idea.paidProductInfo.tptID]?.nbRatings} / {idea.minRates}</div>
                                            <div><i>{idea.paidProductInfo.rateStatus}</i></div>
                                        </td>
                                        <td className="units">
                                            {idea?.sales?.nbUnitsSoldInDateRange > 0 && (
                                                <strong>{idea?.sales?.nbUnitsSoldInDateRange}</strong>
                                            )}
                                            {idea?.sales?.nbUnitsSoldAllTime > 0 && (
                                                <i>/ {idea?.sales?.nbUnitsSoldAllTime}</i>
                                            )}
                                        </td>
                                        <td className="revenue-cell">
                                            {parseInt(idea?.sales?.totalRevenueInDateRange) > 0 && (
                                                <strong>${parseInt(idea?.sales?.totalRevenueInDateRange)}</strong>
                                            )}
                                            {parseInt(idea?.sales?.totalRevenueAllTime) > 0 && (
                                                <i>/ ${parseInt(idea?.sales?.totalRevenueAllTime)}</i>
                                            )}
                                            <FontAwesomeIcon icon={faChartLine} onClick={() => setIdeaInSalesHistoryModal(idea)} />
                                        </td>
                                        <td>${(idea.paidProductInfo.price * 0.8).toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        {Object.keys(totalUnitsSoldByStore).map(store => (
                            <tr key={store}>
                                <td>Total</td>
                                <td>Store {store}</td>
                                <td></td>
                                <td></td>
                                {store !== 'all' ? (
                                    <td>{ideas.filter(p => p.store == store).length} products</td>
                                ) : (
                                    <td></td>
                                )}
                                <td></td>
                                <td>
                                    <strong>
                                        {totalUnitsSoldByStore[store]['inDateRange']} units ({(totalUnitsSoldByStore[store]['inDateRange'] / totalUnitsSoldByStore['all']['inDateRange'] * 100).toFixed(0)}%)
                                    </strong>
                                </td>
                                <td><strong>{parseInt(totalRevenueByStore[store]['inDateRange'])}$</strong></td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

            </div>

            {ideaInSalesHistoryModal !== null && (
                <ProductSalesHistoryModal idea={ideaInSalesHistoryModal} hideModal={() => setIdeaInSalesHistoryModal(null)} />
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    );
}

export default ProductSalesPage;