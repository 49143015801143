import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setStores, setSelectedStoreID } from '../filtersSlice';
import { getDataFromManagerAPI, getDataFromTPTSellerAPI } from '../../../utils/query';
import { useHistory, useLocation } from 'react-router-dom';

import './styles.scss';

const FilterByStore = ({ onlyActiveStores = true, hasAllOption = false, showMetaData = true }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const stores = useSelector((state) => state.filters.stores);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    const [lastStoreRanks, setLastStoreRanks] = useState([]);

    useEffect(() => {
        fetchStores();
        // fetchLastStoreRanks();
    }, []);

    useEffect(() => {
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            const params = new URLSearchParams({ 'selectedStoreID': selectedStoreID });
            history.replace({ pathname: location.pathname, search: params.toString() });
        }
    }, [selectedStoreID]);

    const fetchStores = async () => {
        const res = await getDataFromManagerAPI('resources/stores/' + (onlyActiveStores ? '?active=true' : ''));
        dispatch(setStores(res.data));
        const selectedStoreID = (new URLSearchParams(window.location.search)).get('selectedStoreID');
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            dispatch(setSelectedStoreID(parseInt(selectedStoreID)));
        }
    }

    const fetchLastStoreRanks = async () => {
        const res = await getDataFromTPTSellerAPI('rankchecker/get-last-store-ranks/');
        setLastStoreRanks(res.data);
    }

    return (
        <div className="filter-by-store">
            <Form.Select onChange={e => {
                dispatch(setSelectedStoreID(e.target.value !== 'all' ? parseInt(e.target.value) : null));
            }} value={selectedStoreID ? selectedStoreID : undefined}>
                {hasAllOption && (
                    <option value="all">All</option>
                )}
                {stores.map(store => (
                    <option value={store.id} key={store.id}>
                        G{store.ratedGroup} - {store.id}. {store.name}
                    </option>
                ))}
            </Form.Select>
            {selectedStoreID !== null && showMetaData && (
                <div className="extra-infos">
                    <Row>
                        <Col>
                            <div>Rate Limit: {stores.find(s => s.id === selectedStoreID)?.rateLimit}/{stores.find(s => s.id === selectedStoreID)?.prioRateLimit}</div>
                        </Col>
                        <Col>
                            <a href={stores.find(s => s.id === selectedStoreID)?.url} target="_blank">Store Url</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>Nb Rates: {lastStoreRanks.find(r => r.store === selectedStoreID)?.nbRates}</div>
                        </Col>
                        <Col>
                            <div>Nb Followers: {lastStoreRanks.find(r => r.store === selectedStoreID)?.nbFollowers}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>Nb Paid/All Items:  {lastStoreRanks.find(r => r.store === selectedStoreID)?.nbPaidProducts}/{lastStoreRanks.find(r => r.store === selectedStoreID)?.nbActiveItems}</div>
                        </Col>
                        <Col>
                            <div>Current Rank: {lastStoreRanks.find(r => r.store === selectedStoreID)?.rank}</div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default FilterByStore;