import axios from 'axios';
import Constant from '../Constant';

export const getDataFromManagerAPI = async (url) => {
    const res = await axios.get(Constant.MANAGER_API_URL + url, {
        headers: {
            Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)
        }
    });
    return res;
}

export const updateDataManagerAPI = async (method, url, data = {}) => {
    const options = {
        headers: {
            Authorization: 'Token ' + localStorage.getItem(Constant.LOCAL_STORAGE_TOKEN)
        }
    };
    let res;
    if (method === 'PATCH') {
        res = await axios.patch(Constant.MANAGER_API_URL + url, data, options);
    } else if (method === 'DELETE') {
        res = await axios.delete(Constant.MANAGER_API_URL + url, options);
    } else {
        res = await axios.post(Constant.MANAGER_API_URL + url, data, options);
    }
    return res;
}

export const getDataFromStudioAPI = async (url) => {
    const res = await axios.get(Constant.STUDIO_API_URL + url, {
        headers: {
            Authorization: Constant.STUDIO_API_TOKEN
        }
    });
    return res;
}

export const updateDataStudioAPI = async (method, url, data = {}) => {
    const options = {
        headers: {
            Authorization: Constant.STUDIO_API_TOKEN
        }
    };
    let res;
    if (method === 'PATCH') {
        res = await axios.patch(Constant.STUDIO_API_URL + url, data, options);
    } else if (method === 'DELETE') {
        res = await axios.delete(Constant.STUDIO_API_URL + url, options);
    } else {
        res = await axios.post(Constant.STUDIO_API_URL + url, data, options);
    }
    return res;
}

export const getDataFromTPTSellerAPI = async (url) => {
    const res = await axios.get(Constant.TPTSELLER_API_URL + url, {
        headers: {
            Authorization: Constant.TPTSELLER_API_TOKEN
        }
    });
    return res;
}

export const updateDataTPTSellerAPI = async (method, url, data = {}) => {
    const options = {
        headers: {
            Authorization: Constant.TPTSELLER_API_TOKEN
        }
    };
    let res;
    if (method === 'PATCH') {
        res = await axios.patch(Constant.TPTSELLER_API_URL + url, data, options);
    } else if (method === 'DELETE') {
        res = await axios.delete(Constant.TPTSELLER_API_URL + url, options);
    } else {
        res = await axios.post(Constant.TPTSELLER_API_URL + url, data, options);
    }
    return res;
}
