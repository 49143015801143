import Modal from 'react-bootstrap/Modal';

const NewTaskAlert = ({url, closeModal}) => {
    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>New Task Created Successfully</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <a target="_blank" href={url} rel="noreferrer">{url}</a>
            </Modal.Body>
        </Modal>
    );
}

export default NewTaskAlert;