import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import Constant from '../../Constant';
import { useEffect, useState } from 'react';

const Header = () => {
    const location = useLocation();

    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        setUserRole(localStorage.getItem(Constant.LOCAL_STORAGE_ROLE));
    }, []);

    const logout = () => {
        localStorage.removeItem(Constant.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(Constant.LOCAL_STORAGE_ROLE);
        localStorage.removeItem(Constant.LOCAL_STORAGE_USER_EMAIL);
        window.location.href = '/login';
    }

    return (
        <Navbar bg="light" className="header">
            <Container fluid>
                <Navbar.Brand href="/">
                    <img className="logo" src="/logo.png" alt="logo" />
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="/product-status" className={location.pathname === '/product-status' && 'active'}>
                        Product Status
                    </Nav.Link>
                    {Constant.ROUTE_PERMISSIONS['/product-sales'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/product-sales" className={location.pathname === '/product-sales' && 'active'}>Product Sales</Nav.Link>
                    )}
                    {Constant.ROUTE_PERMISSIONS['/revenue'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/revenue" className={location.pathname === '/revenue' && 'active'}>Revenue</Nav.Link>
                    )}
                    {Constant.ROUTE_PERMISSIONS['/rating-products'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/rating-products" className={location.pathname === '/rating-products' && 'active'}>Rating Products</Nav.Link>
                    )}
                    {Constant.ROUTE_PERMISSIONS['/bot-performance'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/bot-performance" className={location.pathname === '/bot-performance' && 'active'}>Bot Performance</Nav.Link>
                    )}
                    {Constant.ROUTE_PERMISSIONS['/import-reviews'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/import-reviews" className={location.pathname === '/import-reviews' && 'active'}>Import Reviews</Nav.Link>
                    )}
                    {Constant.ROUTE_PERMISSIONS['/testimonials'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/testimonials" className={location.pathname === '/testimonials' && 'active'}>Testimonials</Nav.Link>
                    )}
                    {Constant.ROUTE_PERMISSIONS['/product-stats'].indexOf(userRole) > -1 && (
                        <Nav.Link href="/product-stats" className={location.pathname === '/product-stats' && 'active'}>Stats</Nav.Link>
                    )}
                </Nav>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="logout-btn" onClick={logout}>
                        Logout
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;