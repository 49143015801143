import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import { getDataFromManagerAPI } from '../../../utils/query';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';

const RatePerformanceByProductModal = ({ product, closeModal }) => {
    const [ratePerformance, setRatePerformance] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRatePerformanceByProduct();
    }, []);

    const fetchRatePerformanceByProduct = async () => {
        setLoading(true);
        const res = await getDataFromManagerAPI('rates/get-rate-and-download-performance-by-product/?productID=' + product.id);
        const ratePerformance = [];
        let accumulatedRates = 0, accumulatedDownloads = 0;
        const dates = Object.keys(res.data);
        let minDate = moment(dates[0]), maxDate = moment();
        for (let i = 0; i < dates.length; i++) {
            if (minDate.diff(moment(dates[i]), 'days') > 0) {
                minDate = moment(dates[i]);
            }
        }
        minDate = minDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        maxDate = maxDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        for (let d = minDate.clone(); d.diff(maxDate, 'days') <= 0; d.add(1, 'days')) {
            const date = d.format('YYYY-MM-DD');
            const nbRates = res.data[date] ? res.data[date]['nbRates'] : 0;
            accumulatedRates += nbRates;
            const nbDownloads = res.data[date] ? res.data[date]['nbDownloads'] : 0
            accumulatedDownloads += nbDownloads;
            ratePerformance.push({
                date: date,
                nbRates,
                nbDownloads,
                accumulatedRates,
                accumulatedDownloads,
                minRates: product.minRates,
            });
        }

        setRatePerformance(ratePerformance);
        setLoading(false);
    }

    return (
        <Modal show={true} onHide={() => closeModal()} size="xl" className="rate-performance-by-product-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>Rate Performance - Product {product.customID} - {product.tptID}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="loading-container">
                        <Spinner animation="border" variant="dark" />
                    </div>
                ) : (
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart width={1000} height={300} data={ratePerformance}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            {/* <YAxis yAxisId="right" orientation="right" /> */}
                            <Bar type="monotone" dataKey="accumulatedRates" fill="#11d47a" />
                            <Line type="monotone" dataKey="accumulatedDownloads" stroke="#c4bfbc" />
                            <Line type="monotone" dataKey="nbRates" stroke="#248eab" />
                            <Line type="monotone" dataKey="nbDownloads" stroke="#000" />
                            <Line type="monotone" dataKey="minRates" stroke="#dc3545" />
                            <Tooltip />
                        </ComposedChart>
                    </ResponsiveContainer>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default RatePerformanceByProductModal;