import { Container } from 'react-bootstrap';

const HomePage = () => {
    return (
        <Container className="home-page">
            HOME
        </Container>
    )
}

export default HomePage;