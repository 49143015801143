import Container from 'react-bootstrap/Container';
import './styles.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import axios from 'axios';
import Constant from '../../Constant';

const LoginPage = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const submit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(Constant.MANAGER_API_URL + 'users/auth/', { email, password });
            localStorage.setItem(Constant.LOCAL_STORAGE_TOKEN, res.data['token']);
            localStorage.setItem(Constant.LOCAL_STORAGE_ROLE, res.data['role']);
            localStorage.setItem(Constant.LOCAL_STORAGE_USER_EMAIL, email);
            window.location.href = '/';
        } catch (e) {
            alert('login failed');
        }
    }

    return (
        <Container className='login-form-container'>
            <Form className='login-form'>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={submit}>
                    Submit
                </Button>
            </Form>
        </Container>
    )
}

export default LoginPage;