import { useEffect, useState } from 'react';
import './styles.scss';
import NewTaskAlert from './new-task-alert';
import { useSelector, useDispatch } from 'react-redux';
import { setProducts } from './productStatusSlice';

// React Bootstrap
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AllProductsTable from '../../components/product-status/all-products-table';
import FilterByStore from '../../components/filters/store';
import FilterByGrade from '../../components/filters/grade';
import FilterBySearch from '../../components/filters/search';
import { getDataFromManagerAPI, getDataFromTPTSellerAPI } from '../../utils/query';

const ProductStatusPage = () => {
    const productsByCode = useSelector((state) => state.productStatus.products);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);
    const selectedGrades = useSelector((state) => state.filters.selectedGrades);

    const [productRatings, setProductRatings] = useState({});
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [newTaskUrl, setNewTaskUrl] = useState(null);

    // Table Element Visibility
    const [showRatingsOnly, setShowRatingsOnly] = useState(false);
    const [showGradesColumn, setShowGradesColumn] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchProducts();
    }, [selectedStoreID]);

    const fetchProducts = async () => {
        setLoading(true);
        const res = await getDataFromManagerAPI('resources/products/?additionalFields=minRates,ratePrior&store=' + (selectedStoreID !== null ? selectedStoreID : 1));
        const products = res.data;
        const productsByCode = {};
        products.filter(product => !product.bundle).forEach(product => {
            if (productsByCode[product.customID] === undefined) {
                productsByCode[product.customID] = {
                    store: product.store,
                    free: [],
                    paid: [],
                }
            }

            if (product.saleStatus === 'FREE') {
                productsByCode[product.customID]['free'].push(product);
            } else if (product.saleStatus === 'PAID') {
                productsByCode[product.customID]['paid'].push(product);
            }
        });
        dispatch(setProducts(productsByCode));
        setLoading(false);
        await fetchProductRatings();
    }

    const fetchProductRatings = async () => {
        const res = await getDataFromTPTSellerAPI('rankchecker/get-last-product-ranks/');
        const productRatings = {};
        for (let i = 0; i < res.data.length; i++) {
            const rank = res.data[i];
            const tptID = rank.productData.tptID;
            productRatings[tptID] = rank.nbRatings;
        }
        setProductRatings(productRatings);
    }

    const getFilteredProductsByCode = () => {
        return Object.keys(productsByCode).filter(code => {
            // hide products from other store
            if (productsByCode[code].store !== selectedStoreID) {
                return false;
            }

            const freeProducts = productsByCode[code]['free'];
            const paidProducts = productsByCode[code]['paid'];
            if (showRatingsOnly &&
                freeProducts.filter(p => p.rateStatus === 'RATING').length === 0 &&
                paidProducts.filter(p => p.rateStatus === 'RATING').length === 0
            ) {
                return false;
            }
            if (searchKeyword &&
                freeProducts.filter(p => (p.title && p.title.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1) || (p.customID && p.customID.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)).length === 0 &&
                paidProducts.filter(p => (p.title && p.title.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1) || (p.customID && p.customID.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)).length === 0) {
                return false;
            }

            if (selectedGrades.length > 0 &&
                freeProducts.filter(p => p.grades.split(',').some(item => selectedGrades.includes(item))).length === 0 &&
                paidProducts.filter(p => p.grades.split(',').some(item => selectedGrades.includes(item))).length === 0) {
                return false;
            }

            return true;
        });
    }

    return (
        <Container className="all-products-container" fluid>
            <div className="filter">
                <div className="filter-left">
                    <div>
                        <FilterByStore allStores={false} />
                        <FilterBySearch />
                        <FilterByGrade />
                    </div>
                </div>
                <div className="filter-right">
                    <div className="table-element-visibility">
                        <Form.Group controlId="showRatingOnlyCheckbox">
                            <Form.Check className="text-success" type="checkbox" checked={showRatingsOnly} onChange={e => setShowRatingsOnly(e.target.checked)} label="Show RATING Only" />
                        </Form.Group>
                        <Form.Group controlId="showGradesColumnCheckbox">
                            <Form.Check type="checkbox" label="Show Grades Column" checked={showGradesColumn} onChange={e => setShowGradesColumn(e.target.checked)} />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <AllProductsTable products={getFilteredProductsByCode()}
                productsByCode={productsByCode}
                showGradesColumn={showGradesColumn}
                fetchProducts={fetchProducts}
                productRatings={productRatings}
                refreshAllProducts={fetchProducts}
                setLoading={setLoading}
            />

            {showAlert && newTaskUrl && (
                <NewTaskAlert url={newTaskUrl} closeModal={() => { setShowAlert(false); setNewTaskUrl(null) }} />
            )}

            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default ProductStatusPage;