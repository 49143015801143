import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchKeyword } from '../filtersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const FilterBySearch = () => {
    const dispatch = useDispatch();
    const searchKeyword = useSelector((state) => state.filters.searchKeyword);

    useEffect(() => {
        const searchKeywordParam = (new URLSearchParams(window.location.search)).get('searchKeyword');
        if (searchKeywordParam) {
            dispatch(setSearchKeyword(searchKeywordParam));
        }
    }, []);

    return (
        <div className="filter-by-search">
            <Form.Control value={searchKeyword} placeholder="search by title or code" onChange={e => dispatch(setSearchKeyword(e.target.value))} />
            <FontAwesomeIcon icon={faCircleXmark} onClick={() => dispatch(setSearchKeyword(''))} />
        </div>
    );
}

export default FilterBySearch;