import { useEffect, useState } from "react";

import './styles.scss';
import moment from 'moment';

import { Tooltip, XAxis, YAxis, ResponsiveContainer, ComposedChart, CartesianGrid, Bar, Line } from 'recharts';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { getDataFromManagerAPI } from "../../../utils/query";
import FilterByStore from "../../../components/filters/store";
import { useSelector } from "react-redux";

const BotPerformancePage = () => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    const startDate = moment().subtract(5, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const [overviewPerformance, setOverviewPerformance] = useState([]);
    const [performanceByStore, setPerformanceByStore] = useState({});
    const [nbAliveAccounts, setNbAliveAccounts] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStores();
        // fetchNbAliveAccounts();
    }, []);

    const fetchStores = async () => {
        const res = await getDataFromManagerAPI('resources/stores/?active=true');
        const stores = res.data;
        const performanceByStore = {};
        const overviewPerformance = [];
        setLoading(true);
        for (let i = 0; i < stores.length; i++) {
            const store = stores[i];
            let url = 'rates/get-rate-performance/?startDate=' + startDate + '&endDate=' + endDate + '&store=' + store.id;
            if (selectedStoreID !== null) {
                url += '&store=' + selectedStoreID;
            }
            const res = await getDataFromManagerAPI(url);
            const data = res.data.map(item => {
                if (store.laundering) {
                    return { ...item, recommendedRates: item['nbProducts'] * 5 };
                }
                return { ...item, recommendedRates: item['nbProducts'] * 30 };
            });
            performanceByStore[store.id] = data;
            data.forEach(dailyItem => {
                const index = overviewPerformance.findIndex(item => item['date'] == dailyItem['date']);
                if (index === -1) {
                    overviewPerformance.push(dailyItem);
                } else {
                    overviewPerformance[index]['nbRates'] += dailyItem['nbRates'];
                    overviewPerformance[index]['nbProducts'] += dailyItem['nbProducts'];
                    overviewPerformance[index]['recommendedRates'] += dailyItem['recommendedRates'];
                }
            })
        };
        setPerformanceByStore(performanceByStore);
        setOverviewPerformance(overviewPerformance);
        setLoading(false);
    }

    const fetchNbAliveAccounts = async () => {
        const res = await getDataFromManagerAPI('rates/get-nb-alive-accounts/');
        setNbAliveAccounts(res.data);
    }

    return (
        <Container className="rate-performance-container" fluid>
            <div className="filter">
                <FilterByStore allStores={false} hasAllOption={true} />
            </div>
            <h2>Rates</h2>
            {loading ? (
                <div className="loading-container">
                    <Spinner animation="border" variant="dark" />
                </div>
            ) : (
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart data={selectedStoreID === null ? overviewPerformance : performanceByStore[selectedStoreID]}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Bar type="monotone" dataKey="nbRates" fill="#8884d8" yAxisId="left" />
                        <Line stroke="#000000" dataKey="nbProducts" yAxisId="right" />
                        <Line stroke="#842029" dataKey="recommendedRates" yAxisId="left" />
                    </ComposedChart>
                </ResponsiveContainer>
            )}

            {/* <h2>Accounts: {nbAliveAccounts}</h2> */}
            {/* <BotAccounts startDate={startDate} endDate={endDate} /> */}
        </Container>
    )
}

export default BotPerformancePage;