import { createSlice } from '@reduxjs/toolkit';

export const productStatusSlice = createSlice({
    name: 'productStatus',
    initialState: {
        products: {},
        warnings: []
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
    },
})

export const { setProducts } = productStatusSlice.actions;

export default productStatusSlice.reducer;