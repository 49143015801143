import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveSquare, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Badge, Form, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getDataFromManagerAPI, updateDataManagerAPI } from '../../../utils/query';
import EditProductModal from '../../../pages/product-status/edit-product-modal';
import { useState } from 'react';
import Constant from '../../../Constant';

const RatingProductsTable = ({
    products,
    productRatings,
    selectedRateStatus,
    setSelectedProduct,
    ratingProducts,
    fetchRatingProducts,
    setRatingProducts,
    pausedProducts,
    setPausedProducts }) => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    const [editingProduct, setEditingProduct] = useState(null);

    const updateProductRateStatus = async (product, status) => {
        const ratingProduct = ratingProducts.find(p => p.id === product.id);
        const pausedProduct = pausedProducts.find(p => p.id === product.id);

        if (status === 'RATING') {
            setRatingProducts([...ratingProducts, { ...product, rateStatus: status }]);
            if (pausedProduct) {
                setPausedProducts(pausedProducts.filter(p => p.id !== product.id));
            }
        } else if (status === 'PAUSED') {
            setPausedProducts([...pausedProducts, { ...product, rateStatus: status }]);
            if (ratingProduct) {
                setRatingProducts(ratingProducts.filter(p => p.id !== product.id));
            }
        } else {
            if (pausedProduct) {
                setPausedProducts(pausedProducts.filter(p => p.id !== product.id));
            }
            if (ratingProduct) {
                setRatingProducts(ratingProducts.filter(p => p.id !== product.id));
            }
        }
        await updateDataManagerAPI('PATCH', 'resources/products/' + product.id + '/', { rateStatus: status });
    }

    const updateProductRatePrior = async (product, ratePrior) => {
        if (selectedRateStatus === 'RATING') {
            setRatingProducts(ratingProducts.map(p => {
                if (p.id === product.id) {
                    return { ...p, ratePrior: ratePrior === 'yes' };
                }
                return p;
            }))
        }
        const res = await getDataFromManagerAPI('resources/get-product-idea-by-customID/?customID=' + product.customID);
        await updateDataManagerAPI('PATCH', 'resources/product-ideas/' + res.data['id'] + '/', { ratePrior: ratePrior === 'yes' });
    }

    return (
        <Table bordered hover>
            <thead>
                <tr>
                    <th></th>
                    {selectedStoreID === null && (
                        <th>Store</th>
                    )}
                    <th>Code</th>
                    <th>Name</th>
                    <th>Sale Status</th>
                    <th>Performance</th>
                    <th>Rate Status</th>
                    {selectedRateStatus === 'RATING' && (
                        <th>Rate Prior</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {products.map((product, index) => (
                    <tr key={product.id}>
                        <td>{index + 1}</td>
                        {selectedStoreID === null && (
                            <td>G{product.storeData.ratedGroup} - {product.storeData.name}</td>
                        )}
                        <td>
                            <a href={Constant.MANAGER_API_URL + 'admin/resources/product/' + product.id + '/'} target="_blank">
                                {product.customID}
                            </a>
                        </td>
                        <td className="title-cell">
                            <a href={product.url} target="_blank">
                                {product.title}
                            </a>
                        </td>
                        <td>
                            <Badge bg={product.saleStatus === 'FREE' ? 'danger' : 'success'}>
                                {product.saleStatus}
                            </Badge>
                        </td>
                        <td className="performance-cell">
                            {product.minRates && (
                                <span>{productRatings[product.tptID]}/{product.minRates}</span>
                            )}
                            <div>
                                <span onClick={() => setEditingProduct(product)} className="icon">
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </span>
                                <span onClick={() => setSelectedProduct(product)} className="icon">
                                    <FontAwesomeIcon icon={faWaveSquare} />
                                </span>
                            </div>
                        </td>
                        <td>
                            <Form.Select onChange={e => updateProductRateStatus(product, e.target.value)} value={product.rateStatus} className="product-rate-status">
                                <option value="RATING">RATING</option>
                                <option value="PAUSED">PAUSED</option>
                                <option value="STOP">STOP</option>
                            </Form.Select>
                        </td>
                        {selectedRateStatus === 'RATING' && (
                            <td>
                                <Form.Select onChange={e => updateProductRatePrior(product, e.target.value)} value={product.ratePrior ? 'yes' : 'no'}
                                    className={'product-prior ' + (product.ratePrior ? 'prior' : '')}>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Form.Select>
                                <div>{product.downloadPrior ? 'Download Prior' : ''}</div>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>

            {editingProduct !== null && (
                <EditProductModal product={editingProduct} closeModal={action => {
                    setEditingProduct(null);
                    if (action === 'refresh') {
                        fetchRatingProducts();
                    }
                }} />
            )}
        </Table>
    )
}

export default RatingProductsTable;